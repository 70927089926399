const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/sports-day/sports-2022.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4672.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4673.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4676.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4677.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/sport1.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/sport2.png",
];

export default data;
