import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";

export default function Sports() {
  return (
    <Layout>
      <div className="sports">
        <h1 className="heading">Sports At Chinmaya Vidyalaya</h1>

        <p className="sports__text">
          Chinmaya Vidyalaya Vasant Vihar places equal emphasis on academic and
          co-curricular activities; aiming for perfection in everything
          including sports.
          <br />
          <br />
          The Vidyalaya provides the finest of facilities, giving students the
          opportunity to enjoy a wide range of physical activities, including
          but not limited to basketball, cricket, volleyball, and numerous
          indoor games.
          <br />
          <br />
          Students from all age groups, and all levels of talent and ability can
          enjoy, relax and exercise all the same time, with support from
          dedicated and experienced coaches.
          <br />
          <br />
          Pursuing an activity with enthusiasm offers great satisfaction in
          itself, but our students push themselves on the field to win laurels
          and hone skills that translate readily to other aspects of life.
          <br />
          <br />
          After all, while learning to beat the opposition with skill is the
          goal, the real reward is learning good sportsmanship.
        </p>

        <div className="sports__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-12 col-md-6 col-xl-4">
                <img className="lab__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
